<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="isHomeActive" class="pa-0">
      <v-sheet
        ref="sheet"
        color="#808524"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div>
            <div class="pl-2 pr-2 pt-1 white--text">
              Bienvenue sur le site d’En tête à tête, le nouveau podcast qui
              vous emmène en immersion à la découverte des métiers du groupe
              Labeyrie Fine Foods. Bonne écoute !
            </div>
          </div>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: bold;

  .v-sheet {
    text-align: left;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;
      display: flex;
      justify-content: center;
      display: flex;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.body-2 {
  text-align: center;
}
a {
  color: #164194;
}

.toto {
  display: flex !important;
  justify-content: center;
}
</style>
