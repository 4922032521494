const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '664f533b83390c1069a424d9',
    enableSplashScreen: 'wavy',
    enableExperimentalBlocks: true,
    disabledComponents: [
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
      'wTagLikeCount',
    ],
    metadatas: {
      name: 'EN TÊTE À TÊTE',
      colorPrimary: '#173B69',
      colorAccent: '#808524',
      supportEmailAddress: 'support@podcastlff.com',
    },
    analytics: {
      matomo: '48',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'list',
      },
      ],
        nuxt: {
          robots: {
            UserAgent: '*',
            Allow: '/',
          },
          head: {
            title: 'Les podcasts de Labeyrie Fine Foods',
            meta: [
              {
                charset: 'utf-8',
              },
              {
                name: 'viewport',
                content:
                  'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
              },
              {
                hid: 'description',
                name: 'description',
                content: `A la rencontre des collaborateurs de Labeyrie Fine Foods`,
              },
              {
                'http-equiv': 'X-UA-Compatible',
                content: 'IE=edge',
              },
              {
                'http-equiv': 'X-UA-Compatible',
                content: 'IE=edge',
              },
              {
                charset: 'utf-8',
              },
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
              },
              {
                hid: 'robots',
                name: 'robots',
                content: 'index,follow',
              },
              {
                hid: 'ogSiteName',
                name: 'og:site_name',
                content: 'Labeyrie Fine Foods',
              },
              {
                hid: 'ogDescription',
                name: 'og:description',
                content:
                  'A la rencontre des collaborateurs de Labeyrie Fine Foods',
              },
              {
                hid: 'ogType',
                name: 'og:type',
                content: 'website',
              },
            ],
          },
        },
      },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
